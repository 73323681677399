import { Button, FormGroup, Input, InputGroup } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isEmpty, isEqual, isFunction, map } from 'lodash';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import VideoRecorder from 'react-video-recorder';
import VideoUploader from './videoUploader';
import Player from '../modules/player';

import DefaultImg from '../../assets/images/default_img.svg';
import LOGO from '../../assets/images/helpbuild-logo-white-text.png';

const VideoUploaderWithUrl = ({
  attachable,
  removeAttachment,
  withThumbnails,
}) => {
  const [currentVideoUrl, setCurrentVideoUrl] = useState(attachable.videoUrl);

  const [recording, setRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(false);

  useEffect(() => {
    setCurrentVideoUrl(attachable.videoUrl);
  }, [attachable.videoUrl]);

  const handleChangeUrl = newVal => {
    setCurrentVideoUrl(newVal);
    attachable.update({
      videoUrl: newVal,
      file: null,
      thumbnails: [],
      thumbnail: [],
    });
  };

  const handleThumbnailPicker = e => {
    if (e.target.files && e.target.files[0]) {
      attachable.getBase64(e.target.files[0]).then(data => {
        attachable.update({
          thumbnail: data,
          thumbnails: [],
        });
      });
    }
  };

  return (
    <div className="tour-videoUploader">
      <div>
        {recording ? (
          <div className="helpbuild-video-recorder">
            <VideoRecorder
              isOnInitially
              isFlipped={false}
              replayVideoAutoplayAndLoopOff
              showReplayControls
              onRecordingComplete={videoBlob => {
                setRecordedBlob(videoBlob);
              }}
            />
            <div className="helpbuild-logo-watermark">
              <img src={LOGO} alt="watermark" />
            </div>
          </div>
        ) : (
          <Player
            url={attachable.videoUrlToShow}
            onEnded={() => {}}
            poster={attachable.thumbnail}
          />
        )}
      </div>
      <div className="mt-2">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {isFunction(removeAttachment) && attachable.file && (
          <Button
            type="button"
            className="w-100 mb-3"
            color="danger"
            onClick={removeAttachment}
          >
            <FontAwesomeIcon color="red" icon={['fas', 'trash-alt']} />
          </Button>
        )}
        <div className="d-flex w-100">
          {recording ? (
            <div>
              <Button
                type="button"
                color="secondary"
                className="cursor-pointer file-btn"
                onClick={() => {
                  setRecording(false);
                  setRecordedBlob(null);
                }}
              >
                <FontAwesomeIcon
                  color="red"
                  icon={['fas', 'times']}
                  title="Cancel recording"
                />
              </Button>
              {recordedBlob && (
                <Button
                  type="button"
                  color="secondary"
                  className="cursor-pointer file-btn ml-2"
                  onClick={() => {
                    setRecording(false);
                    attachable.update({
                      thumbnails: [],
                      videoUrl: '',
                      thumbnail: '',
                      file: new File([recordedBlob], 'recorded_video.webm'),
                    });
                    setRecordedBlob(null);
                  }}
                >
                  <FontAwesomeIcon
                    color="green"
                    icon={['fas', 'check']}
                    title="Save recording"
                  />
                </Button>
              )}
            </div>
          ) : (
            <>
              <div>
                <VideoUploader
                  attachable={attachable}
                  onAttachment={() => {
                    attachable.update({
                      thumbnails: [],
                      videoUrl: '',
                      thumbnail: '',
                    });
                  }}
                />
              </div>
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="cursor-pointer file-btn ml-2"
                  onClick={() => setRecording(true)}
                >
                  <FontAwesomeIcon icon={['fas', 'video']} />
                </Button>
              </div>
            </>
          )}
          {!recording && (
            <div className="ml-2 w-100">
              <FormGroup>
                <InputGroup>
                  <Input
                    value={currentVideoUrl}
                    onChange={e => setCurrentVideoUrl(e.target.value)}
                    name="videoUrl"
                    placeholder="or paste a video URL here..."
                  />
                  <Button
                    onClick={() => handleChangeUrl(currentVideoUrl)}
                    type="button"
                    className="input-group-append-btn rounded-0"
                  >
                    <FontAwesomeIcon icon={['fas', 'redo']} />
                  </Button>
                  <Button
                    onClick={() => handleChangeUrl('')}
                    type="button"
                    className="input-group-append-btn rounded-0 rounded-right"
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} />
                  </Button>
                </InputGroup>
              </FormGroup>
            </div>
          )}
        </div>
        {withThumbnails && attachable.showThumbnailPicker && (
          <div className="thumbnail-group">
            <div className="thumbnail-header">
              <h3>Configure Thumbnail</h3>
              <div>
                {isEmpty(attachable.thumbnails) &&
                  attachable.showThumbnailButton && (
                    <Button
                      onClick={() => {
                        attachable.createThumbnails();
                      }}
                      type="button"
                      style={{ minWidth: '100px' }}
                      color="outline-secondary"
                    >
                      Generate Thumbnails
                    </Button>
                  )}
                {attachable.showThumbnailPicker && (
                  <Button
                    type="button"
                    style={{ minWidth: '100px' }}
                    className="thumbnail-picker ml-2"
                    color="outline-secondary"
                  >
                    Upload Custom
                    <input type="file" onChange={handleThumbnailPicker} />
                  </Button>
                )}
              </div>
            </div>
            {(!isEmpty(attachable.thumbnails) ||
              !isEmpty(attachable.thumbnail)) && (
              <div className="thumbnail-body" style={{ gap: 15 }}>
                {!isEmpty(attachable.thumbnails) ? (
                  <div className="video-thumbnails">
                    {map(attachable.thumbnails, (thumbnail, i) => (
                      <img
                        onClick={() => attachable.update({ thumbnail })}
                        key={i}
                        className={classnames({
                          active: isEqual(thumbnail, attachable.thumbnail),
                        })}
                        src={thumbnail}
                        alt=""
                      />
                    ))}
                  </div>
                ) : (
                  <div className="video-thumbnails">
                    {isEmpty(attachable.thumbnail) ? (
                      <img src={DefaultImg} alt="" />
                    ) : (
                      <img
                        className="active"
                        src={attachable.thumbnail}
                        alt=""
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

VideoUploaderWithUrl.defaultProps = {
  withThumbnails: false,
};

// add prop types
VideoUploaderWithUrl.propTypes = {
  attachable: PropTypes.instanceOf(Object).isRequired,
  removeAttachment: PropTypes.func,
  withThumbnails: PropTypes.bool,
};

export default observer(VideoUploaderWithUrl);
