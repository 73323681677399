import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Col, Row } from 'reactstrap';
import Auth from '../shared/_auth';
import t from '../../t';
import BasicForm from '../helpables/basic_form';
import useStore from '../../data/store';

function CreateProject() {
  const { projectStore, appStore } = useStore();

  const { account } = projectStore;

  if (isEmpty(account)) return null;

  if (!account.hasAccess && account.hasAccessPrivate) {
    return (
      <Redirect
        to={{
          pathname: '/404',
        }}
      />
    );
  }

  if (
    !account.hasAccess &&
    account.hasAccessProtected &&
    !appStore.accessCodeVerified
  ) {
    return (
      <Redirect
        to={{
          pathname: '/check-access-code',
          state: {
            redirectUrl: `/create_project`,
          },
        }}
      />
    );
  }

  return (
    <div className="min-vertically-center">
      <Row className="align-items-center flex-grow-1">
        <Col lg={5}>
          <div>
            <h1>{t(`onboard.heading.no_hub`)}</h1>
            <p>{t(`onboard.subheading.default`)}</p>
          </div>
        </Col>
        <Col lg={7}>
          <div>
            <div className="">
              <Auth
                afterAuthMode="complete_project_create"
                afterAuthComponent={BasicForm}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default observer(CreateProject);
