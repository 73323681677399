import React, { useState } from 'react';

import {
  Button,
  Form as ReactForm,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import HintedLabel from '../shared/_hinted_label';
import useStore from '../../data/store';
import RichEditor from '../modules/rich_text_editor';
import { minEditorToolbar } from '../../helpers/shared_helpers';
import t from '../../t';

const HubForm = ({ toggle }) => {
  const { userStore, appStore, projectStore } = useStore();
  const [params, setParams] = useState({
    title: '',
    slug: '',
    description: '',
    type: 'account',
  });

  const handleRawChange = (param, v) => {
    setParams({
      ...params,
      [param]: v,
    });
  };

  const handleChange = e => {
    handleRawChange(e.target.name, e.target.value);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    userStore.currentUser.createHub(params).then(res => {
      if (res) {
        const newUrl = `${window.location.protocol}${projectStore.account.url}/settings`;
        console.log(newUrl);
        // window.location.href = `${window.location.protocol}${projectStore.account.url}/settings`;
      }
    });
  };

  return (
    <ReactForm onSubmit={handleFormSubmit}>
      <FormGroup>
        <HintedLabel helpTextKey="account_name" />
        <Input name="title" value={params.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <HintedLabel helpTextKey="account_domain" />
        <InputGroup>
          <Input name="slug" value={params.slug} onChange={handleChange} />
          <InputGroupAddon className="input-group-append">
            {appStore.baseUrl}
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <HintedLabel helpTextKey="account_description" />
        <RichEditor
          placeholder="What is your hub focused on, who is it bringing together and for what purpose?"
          onChange={m => handleRawChange('description', m)}
          value={params.description}
          toolbar={minEditorToolbar}
          className="editor-xs"
        />
      </FormGroup>
      <Button onClick={handleFormSubmit} className="btn-block" color="primary">
        {t('onboard.hub.form.button')}
      </Button>
    </ReactForm>
  );
};

HubForm.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default observer(HubForm);
