import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';

import {
  camelCase,
  capitalize,
  filter,
  includes,
  isEmpty,
  isEqual,
  isNull,
  isObject,
  map,
} from 'lodash';

import { toast } from 'react-toastify';
import fileExtension from 'file-extension';
import BaseEntity from './base_entity';
import ProjectNeed from './project_need';
import Wallet from './wallet';
import Walletable from '../concerns/walletable';
import ProjectHelper from './project_helper';
import Videoable from '../concerns/videoable';
import Shareable from '../concerns/shareable';
import HelperStore from '../stores/project_helper_store';
import Insight from './insight';
import { videoFormats } from '../../views/modules/player';

class Helpable extends BaseEntity {
  /* eslint-disable */
  isEcosystem;
  @observable title='';
  @observable tagline='';
  @observable description='';
  @observable fetched = false;
  @observable status = 'pending';
  @observable tokens = 0;
  @observable isInitiallyFunded = false;
  @observable consent = false;
  @observable projectNeeds = [];
  @observable createdAt;
  @observable anonymized = false;
  @observable step = 0;
  @observable hasRequests;
  @observable createdBy = {};
  @observable saving = false;
  @observable stats = []
  @observable lastProjectAnnouncement = {}
  @observable errors = [];
  @observable myProjectHelper = null;
  @observable myHelper = null;
  @observable wallet;
  @observable originalId = '';
  @observable settings = {};
  @observable helpableType = 'project';
  @observable leaderboard = [];
  @observable helpers;
  @observable growthInsights;
  @observable engagementInsights;
  @observable score;
  @observable visibility = 'public';
  @observable access = 'public';
  @observable hasAccess = false;
  @observable url = '';
  @observable country = 'virtual';
  @observable projects = null;
  @observable approvedAt = null;
  @observable requiresApproval = false;
  @observable hubRequiresApproval = false;
  @observable approvalRequestedAt = null;
  defaultHubShare = null;
  @observable seoImage = null;
  @observable seoImageUrl = '';
  @observable seoImageFilename = '';
  @observable removeSeoImage = false;
  @observable updatePending = false;
  @observable insights = [];

  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    extendObservable(this, Walletable);

    extendObservable(this, Videoable);
    extendObservable(this, Shareable);

    this.handleConstruction(value);
    this.helpers = new HelperStore(this.store.rootStore, this);
    this.growthInsights = new Insight(this, 'Helpable', [
      'growth_expected',
      'growth_actual',
    ]);
    this.engagementInsights = new Insight(this, 'Helpable', [
      'engagement_asks',
      'engagement_needs',
      'engagement_rewards',
      'engagement_responsiveness',
    ]);
  }

  hasError(key) {
    return includes(this.errors, key);
  }

  @computed
  get amIHelping() {
    return !isEmpty(this.myProjectHelper);
  }

  @computed
  get isProject() {
    return this.helpableType === 'project';
  }

  @computed
  get isPubliclyVisible() {
    return this.visibility === 'public';
  }

  @computed
  get hasVisibilityProtected() {
    return this.visibility === 'protected';
  }

  @computed
  get isPrivatelyVisible() {
    return this.visibility === 'private';
  }

  @computed
  get hasAccessPublic() {
    return this.access === 'public';
  }

  @computed
  get hasAccessProtected() {
    return this.access === 'protected';
  }

  @computed
  get hasAccessPrivate() {
    return this.access === 'private';
  }

  @computed
  get userPermissions() {
    if (isEmpty(this.myProjectHelper)) return [];
    return [...this.myProjectHelper?.permissions];
  }

  @computed
  get shouldBeInWaitingRoom() {
    return this.myProjectHelper?.status !== 'confirmed';
  }

  @computed
  get needs() {
    return filter(this.projectNeeds, { setForDelete: false });
  }

  @computed
  get shareLink() {
    return `${window.location.origin}${this.joinLink}`;
  }

  @action
  hasPermission(permission) {
    if (isEmpty(permission)) return true;

    return [...this.userPermissions].includes(permission);
  }

  @computed
  get sharesPath() {
    if (this.isProject) return `/p/${this.id}/share`;

    return `/shares`;
  }

  @computed
  get dashboardLink() {
    return `/p/${this.id}/`;
  }

  @computed
  get joinLink() {
    return `/p/${this.id}/join`;
  }

  @computed
  get isPending() {
    return this.persisted && isEqual(this.status, 'pending');
  }

  @computed
  get canBeApproved() {
    return this.isPending && isEmpty(this.approvedAt);
  }

  @computed
  get isRejected() {
    return this.persisted && isEqual(this.status, 'rejected');
  }

  @computed
  get approvalRequired() {
    return this.hubRequiresApproval;
  }

  @computed
  get isActive() {
    return this.persisted && isEqual(this.status, 'active');
  }

  @computed
  get asks() {
    return this.store.rootStore.askStore.getMultipleByParams({
      projectId: this.id,
    });
  }

  @computed
  get announcements() {
    return this.store.rootStore.announcementStore.getMultipleByParams({
      projectId: this.id,
    });
  }

  @computed
  get projectHelpers() {
    return this.store.rootStore.projectHelperStore.getMultipleByParams({
      projectId: this.id,
    });
  }

  @computed
  get asTemporaryObj() {
    return new Helpable({ id: this.id, ...this.basicParams }, this.store);
  }

  @computed
  get seoImagePath() {
    if (this.seoImage) return URL.createObjectURL(this.seoImage);
    if (!isEmpty(this.seoImageUrl)) return this.seoImageUrl;

    return null;
  }

  @computed
  get seoFilename() {
    if (this.seoImage) return this.seoImage.name;
    if (this.seoImageFilename) return this.seoImageFilename;

    return 'No File Chosen';
  }

  updateSetting(key, value) {
    this.settings[key] = value;
    this.update({ settings: this.settings });
  }

  @computed
  get basicParams() {
    return {
      title: this.title,
      description: this.description,
      tokens: this.tokens,
      tagline: this.tagline,
      needs: this.projectNeeds.map(need => need.params),
      consent: true,
      video_url: this.videoUrl,
      settings: this.settings,
      type: this.helpableType,
      access_code: this.store.rootStore.appStore.accessCode,
      country: this.country || 'virtual',
    };
  }

  @computed
  get canManage() {
    return (
      this.userPermissions.includes('manage_project') ||
      this.userPermissions.includes('manage_account')
    );
  }

  @computed
  get canManageNeeds() {
    return this.userPermissions.includes('manage_needs');
  }

  @computed
  get canManageAsks() {
    return this.userPermissions.includes('manage_asks');
  }

  @computed
  get sortedProjectNeeds() {
    return filter(this.projectNeeds, { isActive: true })
      .slice()
      .sort((a, b) => {
        if (a.title < b.title) return -1;

        if (a.title > b.title) return 1;

        return 0;
      });
  }

  @computed
  get needsAsSelectOptions() {
    // sort the needs alphabetically
    return map(this.sortedProjectNeeds, need => this.needAsSelectOption(need));
  }

  @computed
  get isUpdatePending() {
    return (
      (this.myProjectHelper?.isBuilder ||
        this.hasPermission('manage_project')) &&
      this.updatePending
    );
  }

  @action
  needAsSelectOption(need) {
    return {
      label: capitalize(need.title),
      value: need.id,
    };
  }

  @computed
  get statusClass() {
    if (isEqual(this.status, 'pending')) return 'warning';
    if (includes(['archived', 'inactive', 'rejected'], this.status))
      return 'danger';

    return 'success';
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  clearCollection(collection) {
    if (!isEmpty(collection)) {
      map(collection, p => p.destroy());
    }
  }

  @action
  addNeed(need) {
    this.projectNeeds.push(need);
    this.validate();
  }

  @action
  removeNeed(need) {
    this.projectNeeds.splice(this.projectNeeds.indexOf(need), 1);
    this.validate();
  }

  @action
  handleConstruction(value) {
    this.json = value;
    this.dirty = false;

    const val = { ...value };

    if (val.wallet) {
      this.wallet = new Wallet(
        val.wallet,
        this.store.rootStore.walletEntryStore
      );
      this.wallet.walletableType = 'Project';

      delete val.wallet;
    }

    if (val.project_needs) {
      this.projectNeeds = [];

      map(val.project_needs, need => {
        this.projectNeeds.push(new ProjectNeed(need, this.store));
      });

      delete val.project_needs;
    }

    if (!isEmpty(value.my_helper)) {
      this.myProjectHelper = new ProjectHelper(
        value.my_helper,
        this.store.rootStore.projectHelperStore
      );
      this.myHelper = this.myProjectHelper;

      delete val.my_helper;
    }

    this.initialize(val);
    this.validate();
  }

  @action
  toggleSelect() {
    map(this.store.records, r => r.update({ selected: false }));

    this.selected = true;
  }

  @action
  reset() {
    if (this.dirty && this.persisted) this.fetch();
  }

  update(params, updateServer) {
    super.update(params, updateServer);
    this.validate();
  }

  @flow
  *create() {
    const response = yield this.client.post(
      '/api/v1/helpables.json',
      this.formDataParams()
    );
    if (response.data.success) {
      this.update({ file: undefined });
      this.handleConstruction(response.data.helpable);
      this.notifySuccess('Project has been created.');

      // push into the store records
      this.store.addRecord(this);
      this.store.updateActiveProject(this.id);
      return true;
    }

    return false;
  }

  @flow
  *sendInvitations(emails) {
    const response = yield this.client.post(
      `/api/v1/helpables/${this.id}/invite_users`,
      {
        emails,
      }
    );

    return response.data.success;
  }

  @action
  validate() {
    this.errors = [];

    if (isEmpty(this.title.trim()))
      this.errors.push(`${this.helpableType}_title`);
    if (isEmpty(this.needs) && this.isProject) this.errors.push('add_needs');
    if (isEmpty(this.videoUrl)) this.errors.push('attach_video');
    return isEmpty(this.errors);
  }

  @computed
  get whiteListedImageExtensions() {
    return ['png', 'jpg', 'jpeg'];
  }

  @computed
  get seoImageValid() {
    if (isNull(this.seoImage)) return false;

    const extension = fileExtension(this.seoImage?.name);

    return (
      isObject(this.seoImage) &&
      includes(this.whiteListedImageExtensions, extension)
    );
  }

  @flow
  *save() {
    if (this.saving) return;
    this.update({ saving: true });

    try {
      const params = this.formDataParams();

      if (this.seoImageValid) {
        params.append('seo_image', this.seoImage);
      }

      params.append('remove_seo_image', `${this.removeSeoImage}`);

      const response = yield this.client.put(
        `/api/v1/helpables/${this.id}`,
        params
      );
      if (!response.data.success)
        this.notifyError(`Update failure: ${response.data.message}`);
      if (response.data.success) {
        this.seoImage = null;
        this.handleConstruction(response.data.helpable);
        this.seoImageFilename = response.data.seo_image_filename;
        this.notifySuccess(this.isProject ? 'Project Updated' : 'Hub Updated');
      }
      this.update({ file: undefined, saving: false });
    } catch (e) {
      this.update({ saving: false });
    }
  }

  @flow
  *join(params) {
    const response = yield this.client.post(
      `/api/v1/helpables/${this.id}/join.json`,
      { ...params, share_id: localStorage.getItem('_user_share_id') }
    );
    if (response.data.success) {
      localStorage.removeItem('_user_share_id');
      this.store.rootStore.userStore.currentUser.update({
        isOnboarded: response.data.is_onboarded,
      });
    }
    return response?.data?.success || false;
  }

  @flow
  *approveProject() {
    try {
      const response = yield this.client.post(
        `/api/v1/helpables/${this.id}/approve.json`
      );

      this.handleConstruction(response.data.project);
      toast.success('Project has been approved.');
      return true;
    } catch (e) {
      return false;
    }
  }

  @flow
  *rejectProject(reason) {
    try {
      const response = yield this.client.post(
        `/api/v1/helpables/${this.id}/reject.json`,
        {
          rejection_reason: reason,
        }
      );

      this.handleConstruction(response.data.project);

      toast.error(
        'Project has been rejected successfully. Project builder will be notified'
      );

      return true;
    } catch (e) {
      return false;
    }
  }

  @flow
  *sendToApproval() {
    try {
      const response = yield this.client.post(
        `/api/v1/helpables/${this.id}/send_for_approval.json`
      );

      this.handleConstruction(response.data.project);

      toast.success(
        'Project has been sent to approval. Hub admin will be notified about this action. You should be notified soon.'
      );

      return true;
    } catch (e) {
      return false;
    }
  }

  @flow
  *moveToEcosystem() {
    try {
      const response = yield this.client.post(
        `/api/v1/helpables/${this.id}/move_to_ecosystem.json`
      );

      toast.success(
        'Project has been moved to Helpbuild ecosystem account successfully.'
      );

      window.location.href = response.data.project.url;

      return true;
    } catch (e) {
      return false;
    }
  }

  @flow
  *fetchInsights() {
    try {
      const response = yield this.client.get(
        `/api/v1/helpables/${this.id}/insights.json`
      );

      this.insights = response.data.insights;
    } catch (e) {
      return false;
    }
  }
}

export default Helpable;
