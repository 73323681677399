import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Form as ReactForm,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { observer } from 'mobx-react';
import { find, isEmpty, isFunction, isNull, isObject } from 'lodash';
import Select from 'react-select';
import t from '../../t';

import useStore from '../../data/store';
import HintedLabel from '../shared/_hinted_label';
import Ask from '../../data/entities/ask';
import RichEditor from '../modules/rich_text_editor';
import { basicEditorToolbar } from '../../helpers/shared_helpers';
import { filterSelectStyles } from '../../helpers/sidebar_helpers';

const Form = ({ toggle, onSubmit, record = null }) => {
  const { askStore, appStore } = useStore();
  const [ask, setAsk] = useState();

  useEffect(() => {
    if (isEmpty(record)) {
      setAsk(
        new Ask(
          {
            title: '',
            description: '',
            projectId: askStore.rootStore.projectStore.activeProjectId,
            maxHelpers: 1,
          },
          askStore
        )
      );
    } else setAsk(record);
  }, [record]);

  if (!ask) return null;

  const handleChange = e => {
    ask.update({ [e.target.name]: e.target.value });
  };

  const handleNeedsChange = e => {
    ask.update({
      projectNeed: find(ask.project.projectNeeds, { id: e.value }),
    });
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (!record) {
      ask.create().then(res => {
        if (res) {
          if (isFunction(onSubmit)) onSubmit(ask.projectId);

          toggle();
        }
      });
    } else {
      ask.save().then(res => {
        if (res) {
          if (isFunction(onSubmit)) onSubmit(ask.projectId);

          toggle();
        }
      });
    }
  };

  return (
    <ReactForm className="ask-form" onSubmit={handleFormSubmit}>
      <Row>
        <Col lg={4}>
          <FormGroup>
            <HintedLabel helpTextKey="what_needs" />
            <Select
              styles={filterSelectStyles}
              onChange={handleNeedsChange}
              options={ask?.project.needsAsSelectOptions}
              value={find(
                ask?.project.needsAsSelectOptions,
                { value: ask.projectNeed.id },
                null
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={8}>
          <FormGroup>
            <HintedLabel helpTextKey="ask_title" />
            <Input
              name="title"
              required
              placeholder={t('ask_placeholder_title')}
              value={ask.title}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup>
            <HintedLabel helpTextKey="ask_description" />
            <RichEditor
              placeholder={t('help.ask_description.text')}
              onChange={m => ask.update({ description: m })}
              value={ask.description}
              toolbar={basicEditorToolbar}
              className="text-cta-editor editor-sm"
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <HintedLabel helpTextKey="acceptance_conditions" />
            <RichEditor
              placeholder={t('help.acceptance_conditions.text')}
              onChange={m => ask.update({ acceptanceConditions: m })}
              value={ask.acceptanceConditions}
              toolbar={basicEditorToolbar}
              className="text-cta-editor editor-sm"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={3} className="border-left">
          <FormGroup>
            <HintedLabel helpTextKey="number_of_helpers" />
            <Input
              type="number"
              name="maxHelpers"
              placeholder="1 or more"
              onChange={handleChange}
              min={1}
              step={1}
              max={appStore.maximumAskHelpers}
              value={ask.maxHelpers}
            />
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <HintedLabel helpTextKey="reward_amount" />
            <Input
              name="rewardAmount"
              min={1}
              type="number"
              placeholder="No. of tokens/coins per helper"
              value={ask.rewardAmount}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <HintedLabel helpTextKey="expiry_date" />
            <Datetime
              timeFormat={false}
              dateFormat="DD MMM, YYYY"
              initialValue={ask.expiresAt}
              inputProps={{
                placeholder: t('ask_placeholder_date_of_expiry'),
              }}
              name="expiresAt"
              onChange={e => {
                if (isObject(e)) ask.update({ expiresAt: e });
              }}
            />
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <Label>Status</Label>
            <Select
              styles={filterSelectStyles}
              onChange={val => ask.update({ publishStatus: val.value })}
              options={ask?.statusOptions}
              value={find(ask?.statusOptions, { value: ask.publishStatus })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {!ask?.promotedToHub && (
          <Col lg={12}>
            <FormGroup>
              <HintedLabel helpTextKey="promote_to_hub" />
              <Select
                styles={filterSelectStyles}
                onChange={val => ask.update({ promoteToHub: val.value })}
                options={ask?.promoteToHubOptions}
                value={find(ask?.promoteToHubOptions, {
                  value: ask.promoteToHub,
                })}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <div className="d-flex justify-content-end gap p-2 mt-3">
        <Button onClick={() => toggle()}>Cancel</Button>
        <Button className="ml-2" color="primary" type="submit">
          {t(record ? 'btn_save_ask' : 'btn_create_ask')}
        </Button>
      </div>
    </ReactForm>
  );
};

Form.propTypes = {
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  record: PropTypes.instanceOf(Object),
};

export default observer(Form);
