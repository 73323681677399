import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import InsightChart from '../shared/_insight_chart';

function ProjectsViewer({ helpables, title }) {
  const [show, setShow] = useState(false);
  return (
    <div className="d-flex align-items-center">
      <div className="mr-2">
        <strong>{helpables.length}</strong>
      </div>
      {helpables.length > 0 && (
        <Badge
          onClick={() => setShow(true)}
          className="cursor-pointer small mb-0"
          color="primary"
        >
          Show
        </Badge>
      )}

      <Modal isOpen={show} toggle={() => setShow(!show)}>
        <ModalHeader toggle={() => setShow(!show)}>{title}</ModalHeader>
        <ModalBody>
          {helpables.map(helpable => (
            <div className={helpable.project_id}>
              <Link to={`/p/${helpable.project_id}`}>
                {helpable.project_title}
              </Link>
              <hr />
            </div>
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
}

const Insights = ({ helpable }) => {
  useEffect(() => {
    helpable.fetchInsights();
  }, []);

  console.log(helpable.insights);

  return (
    <div>
      <div className="project_form_wrapper">
        <div className="project_form_wrapper__sticky">
          <div className="border_box project_form_wrapper__content">
            <h2>Insights</h2>
          </div>
        </div>
      </div>
      <div className="ml-3">
        <div className="border_box p-3">
          <h6>How well covered are the needs in your hub being met?</h6>
          <Table>
            <thead>
              <tr>
                <th>Need</th>
                {/* <th>Helpers Subscribed</th> */}

                {!helpable.isProject && (
                  <>
                    <th>Hub Helpers Subscribed</th>
                    {/* <th> */}
                    {/*  <div>Projects with helpers</div> */}
                    {/* </th> */}
                    <th>
                      <div>Projects with no helpers</div>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {helpable.insights.map((record, i) => (
                <tr key={i}>
                  <td>{record.title}</td>
                  {/* <td>{record.project_helpers_subscribed}</td> */}
                  <>
                    {!helpable.isProject && (
                      <>
                        <td>{record.hub_helpers_subscribed}</td>
                        {/* <td> */}
                        {/*  <ProjectsViewer */}
                        {/*    title="Projects having subscribers" */}
                        {/*    helpables={record.projects_with_subscribers} */}
                        {/*  /> */}
                        {/* </td> */}
                        <td>
                          <ProjectsViewer
                            title="Subscribers needed for these projects"
                            helpables={record.projects_without_subscribers}
                          />
                        </td>
                      </>
                    )}
                  </>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="border_box p-3">
          <h6>Network Health of Hubs and Projects?</h6>
        </div>
      </div>
    </div>
  );
};

Insights.propTypes = {
  helpable: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Insights);
